import { helpers, required } from '@vuelidate/validators'

export const dobFormat = helpers.regex(/(0[1-9]|1[012])[/.](0[1-9]|[12][0-9]|3[01])[/.]((?:19|20)\d\d)/)

export function kebabToSpaces(str) {
  return String(str).replace(/-/g, ' ')
}

export function camelCaseToSpaces(str) {
  const spaced = String(str).replace(/([A-Z])/g, ' $1')
  return spaced.charAt(0).toUpperCase() + spaced.slice(1)
}

export function underscoreToSpaces(str) {
  return String(str).replace(/_/g, ' ')
}

export function underscoresToCamelCase(str) {
  return String(str.replace(/_./g, m => m[1].toUpperCase()))
}

export function capitalize(str) {
  const words = str.split(' ')

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }

  return words.join(' ')
}

export const requiredMessage = 'This question is required.'
export const requiredHelperWithMessage = helpers.withMessage(requiredMessage, required)
export const cleanupStepTitle = 'Help With Clean Up'
/**
 * This function provides standard yes/no radio options to our Kudo Radio Group components
 */
export function getTrueFalseRadioOptions(includeUnsureOption) {
  const radios = [{
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  }]

  if (includeUnsureOption) {
    radios.push({
      label: 'Not Sure',
      value: 'not_sure',
    })
  }
  return radios
}

/**
 * This function takes in the a key from store state and returns the question label string
*/
export function getQuestionForStateKey(key) {
  switch (key) {
    case 'sourceOfWaterKnown': return 'Do you know where the water came from?'
    case 'sourceOfFireKnown': return 'Do you know what caused the fire?'
    case 'sourceOfWater': return 'Source of water'
    case 'sourceOfFire': return 'Source of fire'
    case 'hasManufacturerInfo': return 'Do you have information about the appliance or manufacturer?'
    case 'manufacturerName': return 'Manufacturer Name'
    case 'manufactureDate': return 'Date of Manufacture'
    case 'serialNumber': return 'Serial Number'
    case 'modelNumber': return 'Model Number'
    case 'didLosePower': return 'Did you lose power?'
    case 'isHomeSafe': return 'Are you able to live at home safely?'
    case 'didContactMitigation': return 'Have you hired a company to handle clean up and repairs?'
    case 'anyOtherContacts': return 'Does anyone else need to be added to this claim?'
    case 'mitigationCompanyName': return 'Which company did you contact?'
    case 'hasHolesInDoorsWindows': return 'Are there any holes in the roof, doors, or windows which need to be covered?'
    case 'hasStandingWater': return 'Is there currently standing water or saturated building materials in the house?'
    case 'isWaterTurnedOff': return 'Has the water source been turned off?'
    case 'didTurnoffStopWater': return 'Did this stop the water?'
    case 'isImmediateAssistanceNeeded': return 'Do you need immediate assistance or help to secure the home?'
    case 'didFireDepartmentCome': return 'Did the fire department come to the house?'
    case 'wasSmokeMainCauseOfDamage': return 'Was smoke the main cause of damage?'
    case 'injuredHasAttorney': return 'Is the injured person represented by an attorney?'
    case 'ownerHasAttorney': return 'Is the property owner represented by an attorney?'
    case 'isLawsuitFiled': return 'Has a lawsuit been filed/served?'
    case 'isRotOrMoldPresent': return 'Have you noticed any rot or mold in the damaged area or in any adjacent areas?'
    case 'werePoliceNotified': return 'Were the police notified/involved?'
    case 'wasReportFiled': return 'Was a police report filed?'
    case 'policeReportNumber': return 'Report Number'
    case 'hasPropertyOwnerInformation': return 'Do you have the property owner’s information?'
    case 'propertyOwnerFirstName': return 'Property Owner\'s First Name'
    case 'propertyOwnerLastName': return 'Property Owner\'s Last Name'
    case 'propertyOwnerDateOfBirth': return 'Property Owner\'s Date of Birth'
    case 'propertyOwnerPhone': return 'Property Owner\'s Phone Number'
    case 'propertyOwnerStreet': return 'Property Owner\'s Street Address Line One'
    case 'propertyOwnerUnit': return 'Property Owner\'s Unit'
    case 'propertyOwnerCity': return 'Property Owner\'s City'
    case 'propertyOwnerState': return 'Property Owner\'s State'
    case 'propertyOwnerZip': return 'Property Owner\'s Zip Code'
    case 'injuredFirstName': return 'Injured Person\'s First Name'
    case 'injuredLastName': return 'Injured Person\'s Last Name'
    case 'injuredDateOfBirth': return 'Injured Person\'s Date of Birth'
    case 'injuredPhone': return 'Injured Person\'s Phone Number'
    case 'injuredEmail': return 'Injured Person\'s Email'
    case 'injuredStreet': return 'Injured Person\'s Street Address Line One'
    case 'injuredUnit': return 'Injured Person\'s Street Address Line Two'
    case 'injuredCity': return 'Injured Person\'s City'
    case 'injuredState': return 'Injured Person\'s State'
    case 'injuredZip': return 'Injured Person\'s Zip Code'
    case 'didIncidentOccurAtProperty': return 'Did the incident occur at the property address?'
    case 'incidentStreetAddress': return 'Incident Street Address Line One'
    case 'incidentStreetAddressLineTwo': return 'Incident Street Address Line Two'
    case 'incidentUnit': return 'Incident Street Address Line Two'
    case 'incidentCity': return 'Incident City'
    case 'incidentState': return 'Incident State'
    case 'incidentZip': return 'Incident Zip Code'
    case 'wasHomeBrokenInto': return 'Did someone break into your home?'
    case 'wasArrestMade': return 'Was an arrest made?'
    case 'hasInjuredPersonsInformation': return 'Do you have the injured person’s information?'
    case 'wasRoofDamaged': return 'Was the roof damaged?'
    case 'hasResultingWaterDamage': return 'Was there resulting water damage?'
    case 'didReceiveMedicalTreatment': return 'Did the injured person receive medical care/treatment?'
    case 'propertyOwnerEmail': return 'Property Owner\'s Email'
    case 'contactType': return 'Contact Type'
    case 'organizationName': return 'Organization Name'
    case 'descriptionOfTreatment': return 'Description of Treatment'
    case 'didContactPlumber': return 'Have you contacted a plumber?'
    case 'didAdviseOnPlumber': return 'I have advised the customer to contact a plumber'
    case 'helpWithWaterCleanup': return 'It sounds like you have not started cleaning up the water damage yet. We partner with water clean up professionals who can help. Would you like to be contacted if one is available in your area?'
    default: return 'Not found'
  }
}

/**
 * This function takes an object formatted as such:
 * {
 *   value1: {
 *     label: '2',
 *   },
 *   value2: {
 *     label: '1',
 *   }
 * }
 * and transforms it into the appropriate data format for the `kudo-select` component.
 * * `label` - The label string value within each object
 * * `value` - The key of each object
 * @returns {{ label: string, value: string }[]}
 */
export function mapToKudoSelectFormat(options) {
  return Object.keys(options).map((key) => {
    return { label: options[key].label, value: key }
  })
}

export const waterSources = {
  appliance: {
    label: 'Appliance/Water Heater',
  },
  brokenPipe: {
    label: 'Broken Pipe',
  },
  exteriorWall: {
    label: 'Exterior Wall',
  },
  foundationBasementWall: {
    label: 'Foundation/Basement Wall',
  },
  groundWater: {
    label: 'Ground Water',
  },
  neighboringPremises: {
    label: 'Neighboring Premises',
  },
  roof: {
    label: 'Roof',
  },
  sinkTubToilet: {
    label: 'Sink/Tub/Toilet/Shower',
  },
  window: {
    label: 'Window',
  },
  other: {
    label: 'Other',
  },
}

export const fireSources = {
  appliance: {
    label: 'Appliance',
  },
  arson: {
    label: 'Arson',
  },
  candle: {
    label: 'Candle',
  },
  cookingAppliance: {
    label: 'Cooking Appliance',
  },
  electrical: {
    label: 'Electrical',
  },
  fireplace: {
    label: 'Fireplace',
  },
  lightning: {
    label: 'Lightning',
  },
  neighboringPremises: {
    label: 'Neighboring Premises',
  },
  wildfire: {
    label: 'Wildfire',
  },
  other: {
    label: 'Other',
  },
  // TODO: We will refactor this out when we update to the new source of fire UI!
  unknown: {
    label: 'Unknown',
  },
}

/**
 * This function, given a source of damage, will return the human-readable (AKA label value)
 * for that damage
 * @returns {string}
 */
export function displaySourceOfDamage(sourceOfDamage) {
  const allSources = {
    ...waterSources,
    ...fireSources,
  }

  return allSources[sourceOfDamage].label
}

export const waterSourceKudoOptions = mapToKudoSelectFormat(waterSources)
export const fireSourceKudoOptions = mapToKudoSelectFormat(fireSources)
