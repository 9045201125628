import { mapToKudoSelectFormat } from './utilities'

export const maxNumOfDamageTypes = 8

export const causeOfDamageOptions = {
  water: {
    label: 'Water',
  },
  weather: {
    label: 'Weather',
  },
  fire: {
    label: 'Fire',
  },
  equipmentBreakdown: {
    label: 'Equipment Breakdown',
  },
  injuryOrLiability: {
    label: 'Injury or Liability',
  },
  theft: {
    label: 'Theft',
  },
  vandalism: {
    label: 'Vandalism',
  },
  other: {
    label: 'Other',
  },
}

const waterDamageTypes = [
  { label: 'Frozen Pipe Burst', value: 'frozenPipeBurst' },
  { label: 'Water/Sewer Backup', value: 'waterSewerBackup' },
  { label: 'Sump Pump Failure', value: 'sumpPumpFailure' },
  { label: 'Rain', value: 'rain' },
  { label: 'Flooding', value: 'flooding' },
  { label: 'Water Damage', value: 'waterDamage' },
]

const injuryOrLiabilityDamageTypes = [
  { label: 'Dog Bite', value: 'dogBite' },
  { label: 'Slip/Fall', value: 'slipFall' },
  { label: 'Other Injury', value: 'otherInjury' },
  { label: 'Third Party Property Damage', value: 'thirdPartyPropertyDamage' },
]

const weatherDamageTypes = [
  { label: 'Hail', value: 'hail' },
  { label: 'Ice Damage', value: 'iceDamage' },
  { label: 'Lightning', value: 'lightning' },
  { label: 'Weight of Ice and Snow', value: 'weightOfIceAndSnow' },
  { label: 'Wind', value: 'wind' },
  { label: 'Wind-Driven Rain', value: 'windDrivenRain' },
]

const otherDamageTypes = [
  { label: 'Broken Window', value: 'brokenWindow' },
  { label: 'Collapse', value: 'collapse' },
  { label: 'Collision', value: 'collision' },
  { label: 'Earthquake', value: 'earthquake' },
  { label: 'Explosion', value: 'explosion' },
  { label: 'Falling Object', value: 'fallingObject' },
  { label: 'Power Surge', value: 'powerSurge' },
  { label: 'Service Line Damage', value: 'serviceLineDamage' },
  { label: 'Sinkhole', value: 'sinkhole' },
  { label: 'Other', value: 'other' },
]

export function getDamageTypeOptions(causeOfDamage) {
  switch (causeOfDamage) {
    case 'water': return waterDamageTypes
    case 'injuryOrLiability': return injuryOrLiabilityDamageTypes
    case 'weather': return weatherDamageTypes
    case 'other': return otherDamageTypes
    case 'fire':
    case 'equipmentBreakdown':
    case 'theft':
    case 'vandalism':
    default:
      return []
  }
}

export function getCauseOfDamageLabel(causeOfDamage) {
  return causeOfDamageOptions[causeOfDamage]?.label || ''
}

export function displayDamageType(damageType) {
  const allDamageTypes = [
    ...waterDamageTypes,
    ...injuryOrLiabilityDamageTypes,
    ...weatherDamageTypes,
    ...otherDamageTypes,
  ]

  const damageTypeObject = allDamageTypes.find(type => type.value === damageType)
  return damageTypeObject?.label || ''
}

export const causeOfDamageKudoOptions = mapToKudoSelectFormat(causeOfDamageOptions)

export const causeOfDamageWithoutDamageTypes = ['equipmentBreakdown', 'vandalism', 'theft', 'fire']
