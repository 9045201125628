export default () => {
  return {
    isImmediateAssistanceNeeded: null,
    hasHolesInDoorsWindows: null,
    wasRoofDamaged: null,
    hasResultingWaterDamage: null,
    didLosePower: null,
    hasStandingWater: null,
    isRotOrMoldPresent: null,
    wasHomeBrokenInto: null,
    isHomeSafe: null,
    isWaterTurnedOff: null,
    didTurnoffStopWater: null,
    didContactPlumber: null,
    didAdviseOnPlumber: null,
    didFireDepartmentCome: null,
    injuredHasAttorney: null,
    ownerHasAttorney: null,
    isLawsuitFiled: null,
    wasArrestMade: null,
    didContactMitigation: null,
    mitigationCompanyName: '',
    propertyOwnerContactIds: [],
    didIncidentOccurAtProperty: null,
    wasSmokeMainCauseOfDamage: null,
    incidentLocation: {
      description: '',
      streetLineOne: '',
      streetLineTwo: '',
      city: '',
      region: '',
      postalCode: '',
    },
    werePoliceNotified: null,
    wasReportFiled: null,
    policeReportNumber: '',
    didReceiveMedicalTreatment: null,
    descriptionOfTreatment: '',
    hasInjuredPersonsInformation: null,
    injuredPersonContactIds: [],
    sameAsIncidentAddress: false,
    hasPropertyOwnerInformation: null,
    anyOtherContacts: null,
    hasManufacturerInfo: null,
    manufacturerInfo: {
      manufacturerName: '',
      manufactureDate: '',
      serialNumber: '',
      modelNumber: '',
    },
  }
}

export const orderedQuestions = [
  'hasManufacturerInfo',
  'manufacturerInfo',
  'isWaterTurnedOff',
  'didTurnoffStopWater',
  'didContactPlumber',
  'didAdviseOnPlumber',
  'hasStandingWater',
  'isRotOrMoldPresent',
  'wasSmokeMainCauseOfDamage',
  'wasRoofDamaged',
  'didFireDepartmentCome',
  'hasResultingWaterDamage',
  'hasHolesInDoorsWindows',
  'wasHomeBrokenInto',
  'didIncidentOccurAtProperty',
  'incidentLocation',
  'werePoliceNotified',
  'wasReportFiled',
  'policeReportNumber',
  'wasArrestMade',
  'didReceiveMedicalTreatment',
  'descriptionOfTreatment',
  'ownerHasAttorney',
  'injuredHasAttorney',
  'isLawsuitFiled',
  'isHomeSafe',
  'didLosePower',
  'isImmediateAssistanceNeeded',
  'didContactMitigation',
  'mitigationCompanyName',
  'hasInjuredPersonsInformation',
  'injuredDetails',
  'hasPropertyOwnerInformation',
  'propertyOwnerDetails',
  'propertyOwnerContactIds',
  'injuredPersonContactIds',
  'anyOtherContacts',
]
