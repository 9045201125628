import dayjs from 'dayjs'

const policyHolderContactBase = {
  relationToInsured: 'insured',
  type: 'person',
  id: 'policyholder',
  isReporter: false,
  isInitialContact: true,
  isOther: false,
  label: 'Policyholder',
}

export default {
  setCurrentStep(state, step) {
    state.currentStep = step
  },
  resetCurrentStepToZero(state) {
    state.currentStep = 0
  },
  setStepCompleteStatus(state, step) {
    state.claimSteps[step.index].complete = step.complete
  },
  setNewPolicyInfo(state, policyInfo) {
    state.policyInfo = {
      ...policyInfo,
      dateOfBirth: policyInfo.dateOfBirth,
      dateOfLoss: dayjs(policyInfo.dateOfLoss).format('YYYY-MM-DD'),
    }
  },
  setPolicyInfoFromPolicyNumberVerification(state, payload) {
    state.policyInfo = {
      dateOfBirth: null,
      address1: payload.streetLineOne,
      address2: payload.streetLineTwo,
      city: payload.city,
      state: payload.state,
      postalCode: payload.zip,
      country: 'US',
      dateOfLoss: dayjs(payload.formState.dateOfLoss).format('YYYY-MM-DD'),
      policyHolderFirstName: payload.firstName,
      policyHolderLastName: payload.lastName,
      doesKnowPolicyNumber: payload.formState.doesKnowPolicyNumber,
      userInputPolicyNumber: payload.formState.userInputPolicyNumber,
    }
  },
  // Create the initial policyholder contact
  setPolicyHolderContact(state, personalInfo) {
    // If a policyholder exists, remove it
    state.contacts = state.contacts.filter((contact) => {
      return contact.id !== 'policyholder'
    })
    // then push the policyholder info from the lookup
    state.contacts.push({
      ...personalInfo,
      ...policyHolderContactBase,
    })
  },
  setPolicyInfo(state, policyInfo) {
    state.policyInfo = {
      ...policyInfo,
    }
  },
  setPolicyNumber(state, policyNumber) {
    state.policyNumber = policyNumber
  },
  setPolicyHolderFirstName(state, firstName) {
    state.policyInfo.policyHolderFirstName = firstName
  },
  setTxKey(state, txKey) {
    state.txKey = txKey
  },
  clearTxKey(state) {
    state.txKey = ''
  },
  setNotifierInfo(state, notifierInfo) {
    state.notifier = {
      ...notifierInfo,
    }
  },
  clearPolicyHolderContactInfo(state) {
    state.policyHolderContactInfo = {
      policyHolderFirstName: '',
      policyHolderLastName: '',
      policyHolderEmail: '',
      policyHolderPhoneNumber: '',
    }
  },
  setCauseOfDamage(state, val) {
    state.causeOfDamage = val
  },
  setDamageType(state, val) {
    state.damageType = val
  },
  setPropertyDamages(state, val) {
    state.propertyDamage.damages = val
  },
  setAnyUnfinishedDamagedRooms(state, val) {
    state.propertyDamage.anyUnfinishedDamagedRooms = val
  },
  setHasExteriorDamage(state, val) {
    state.propertyDamage.hasExteriorDamage = val
  },
  setNumberRoomsDamaged(state, val) {
    state.propertyDamage.numberRoomsDamaged = val
  },
  setNumberStructuresDamaged(state, val) {
    state.propertyDamage.numberStructuresDamaged = val
  },
  setNumberItemsDamaged(state, val) {
    state.propertyDamage.numberItemsDamaged = val
  },
  setLossOfLife(state, val) {
    state.propertyDamage.lossOfLife = val
  },
  setDamagesDescription(state, payload) {
    state.damagesDescription = payload
  },
  setAdditionalDetails(state, payload) {
    state.additional = payload
  },
  resetAttachments(state) {
    state.attachments = []
  },
  setAttachmentLoading(state, file) {
    state.attachments.push(file)
  },
  setAttachmentStatus(state, payload) {
    const fileToUpdate = state.attachments.find(file => file.id === payload.id)
    fileToUpdate.status = payload.status
  },
  setAttachmentObjectName(state, payload) {
    const fileToUpdate = state.attachments.find(file => file.id === payload.id)
    fileToUpdate.objectName = payload.objectName
  },
  removeAttachment(state, id) {
    state.attachments = state.attachments.filter((file) => {
      return file.id !== id
    })
  },
  setClaimNumber(state, claimNumber) {
    state.claimNumber = claimNumber
  },
  setVendorAssignments(state, vendorAssignments) {
    state.vendorAssignments = vendorAssignments
  },
  setTaskAssignments(state, taskAssignments) {
    state.taskAssignments = taskAssignments
  },
  setClaimSteps(state, newSteps) {
    state.claimSteps = newSteps
  },
  setHasInjuredPersonsInformation(state, value) {
    state.additional.hasInjuredPersonsInformation = value
  },
  setHasPropertyOwnerInformation(state, value) {
    state.additional.hasPropertyOwnerInformation = value
  },
  clearIncidentLocationForm(state) {
    state.additional.incidentLocation.description = ''
    state.additional.incidentLocation.streetLineOne = ''
    state.additional.incidentLocation.streetLineTwo = ''
    state.additional.incidentLocation.city = ''
    state.additional.incidentLocation.region = ''
    state.additional.incidentLocation.postalCode = ''
  },
  setIncidentLocationToPropertyAddress(state) {
    state.additional.incidentLocation.description = ''
    state.additional.incidentLocation.streetLineOne = state.policyInfo.address1
    state.additional.incidentLocation.streetLineTwo = state.policyInfo.address2
    state.additional.incidentLocation.city = state.policyInfo.city
    state.additional.incidentLocation.region = state.policyInfo.state
    state.additional.incidentLocation.postalCode = state.policyInfo.postalCode
  },
  clearPropertyOwnerContactOnly(state) {
    state.additional.propertyOwnerDetails.street = ''
    state.additional.propertyOwnerDetails.unit = ''
    state.additional.propertyOwnerDetails.city = ''
    state.additional.propertyOwnerDetails.region = ''
    state.additional.propertyOwnerDetails.postalCode = ''
  },
  // start custom 'set' question mutations from yes-no-question component
  // these function names are dynamically generated from key names
  setIsImmediateAssistanceNeeded(state, value) {
    state.additional.isImmediateAssistanceNeeded = value
  },
  setWasRoofDamaged(state, value) {
    state.additional.wasRoofDamaged = value
  },
  setIsHomeSafe(state, value) {
    state.additional.isHomeSafe = value
  },
  setDidFireDepartmentCome(state, value) {
    state.additional.didFireDepartmentCome = value
  },
  setDidContactMitigation(state, value) {
    state.additional.didContactMitigation = value
  },
  setAnyOtherContacts(state, value) {
    state.additional.anyOtherContacts = value
  },
  setSourceOfDamage(state, value) {
    state.sourceOfDamage = value
  },
  setDoesKnowSourceOfDamage(state, value) {
    state.doesKnowSourceOfDamage = value
  },
  setWerePoliceNotified(state, value) {
    state.additional.werePoliceNotified = value
  },
  setWasHomeBrokenInto(state, value) {
    state.additional.wasHomeBrokenInto = value
  },
  setHasHolesInDoorsWindows(state, value) {
    state.additional.hasHolesInDoorsWindows = value
  },
  setHasResultingWaterDamage(state, value) {
    state.additional.hasResultingWaterDamage = value
  },
  setIsRotOrMoldPresent(state, value) {
    state.additional.isRotOrMoldPresent = value
  },
  setDidLosePower(state, value) {
    state.additional.didLosePower = value
  },
  setWasArrestMade(state, value) {
    state.additional.wasArrestMade = value
  },
  setWasReportFiled(state, value) {
    state.additional.wasReportFiled = value
  },
  setPoliceReportNumber(state, value) {
    state.additional.policeReportNumber = value
  },
  setDidIncidentOccurAtProperty(state, value) {
    state.additional.didIncidentOccurAtProperty = value
  },
  setIncidentLocationDescription(state, value) {
    state.additional.incidentLocation.description = value
  },
  setIncidentLocationStreetLineOne(state, value) {
    state.additional.incidentLocation.streetLineOne = value
  },
  setIncidentLocationStreetLineTwo(state, value) {
    state.additional.incidentLocation.streetLineTwo = value
  },
  setIncidentLocationCity(state, value) {
    state.additional.incidentLocation.city = value
  },
  setIncidentLocationState(state, value) {
    state.additional.incidentLocation.region = value
  },
  setIncidentLocationZip(state, value) {
    state.additional.incidentLocation.postalCode = value
  },
  setInjuredHasAttorney(state, value) {
    state.additional.injuredHasAttorney = value
  },
  setOwnerHasAttorney(state, value) {
    state.additional.ownerHasAttorney = value
  },
  setIsLawsuitFiled(state, value) {
    state.additional.isLawsuitFiled = value
  },
  setDidReceiveMedicalTreatment(state, value) {
    state.additional.didReceiveMedicalTreatment = value
  },
  setDescriptionOfTreatment(state, value) {
    state.additional.descriptionOfTreatment = value
  },
  setHasStandingWater(state, value) {
    state.additional.hasStandingWater = value
  },
  setIsWaterTurnedOff(state, value) {
    state.additional.isWaterTurnedOff = value
  },
  setDidTurnoffStopWater(state, value) {
    state.additional.didTurnoffStopWater = value
  },
  setWasSmokeMainCauseOfDamage(state, value) {
    state.additional.wasSmokeMainCauseOfDamage = value
  },
  setPartnerContactOptInsStatus(state, value) {
    const partnerIndex = state.partnerContactOptIns.findIndex(partnerContact => partnerContact.task === value.task)
    state.partnerContactOptIns[partnerIndex].canContact = value.canContact
  },
  setPartnerContactAdditionalPhone(state, value) {
    state.partnerContactAdditionalPhone = value
  },
  setHasManufacturerInfo(state, value) {
    state.additional.hasManufacturerInfo = value
  },
  setManufacturerName(state, value) {
    state.additional.manufacturerInfo.manufacturerName = value
  },
  setManufactureDate(state, value) {
    state.additional.manufacturerInfo.manufactureDate = value
  },
  setSerialNumber(state, value) {
    state.additional.manufacturerInfo.serialNumber = value
  },
  setModelNumber(state, value) {
    state.additional.manufacturerInfo.modelNumber = value
  },
  // end custom 'set' question mutations from yes-no-question component
  // start contact-info mutations //
  setNotifierFirstName(state, value) {
    state.notifier.firstName = value
  },
  setNotifierLastName(state, value) {
    state.notifier.lastName = value
  },
  setNotifierRelationToInsured(state, value) {
    state.notifier.relationToInsured = value
  },
  setNotifierEmail(state, value) {
    state.notifier.email = value
  },
  setNotifierPhone(state, value) {
    state.notifier.phone = value
  },
  setNotifierAcceptTextMessages(state, value) {
    state.notifier.acceptsTextMessages = value
  },
  setNotifierAcceptEmailMessages(state, value) {
    state.notifier.acceptsEmailMessages = value
  },
  setNotifierIsPolicyHolder(state, value) {
    state.notifier.isPolicyHolder = value
  },
  setNotifierAcceptFraudDisclaimer(state, value) {
    state.notifier.acceptFraudDisclaimer = value
  },
  setNotifierType(state, value) {
    state.notifier.notifierType = value
  },
  setPolicyHolderContactInfoFirstName(state, value) {
    state.policyHolderContactInfo.policyHolderFirstName = value
  },
  setPolicyHolderContactInfoLastName(state, value) {
    state.policyHolderContactInfo.policyHolderLastName = value
  },
  setPolicyHolderContactInfoEmail(state, value) {
    state.policyHolderContactInfo.policyHolderEmail = value
  },
  setPolicyHolderContactInfoPhoneNumber(state, value) {
    state.policyHolderContactInfo.policyHolderPhoneNumber = value
  },
  // end contact-info mutations //
  // new contact mutations //
  addContact(state, value) {
    state.contacts.push(value.contact)
  },
  updateContact(state, value) {
    state.contacts = state.contacts.map((contactInstance) => {
      if (contactInstance.id === value.id) {
        const newContact = { ...contactInstance, ...value }
        // delete the `unsaved` property on the updated contact because we are saving it
        delete newContact.unsaved
        return newContact
      } else {
        return contactInstance
      }
    })
  },
  deleteContact(state, value) {
    state.contacts = state.contacts
      .filter(contactInstance => contactInstance.id !== value.id)
  },
  deleteUnsavedContacts(state) {
    state.contacts = state.contacts
      .filter(contactInstance => !contactInstance.unsaved)
  },
  deleteContactsByLabel(state, value) {
    state.contacts = state.contacts
      .filter(contactInstance => contactInstance.label !== value)
  },
  resetContacts(state) {
    state.contacts = []
  },
  /**
 * @function
 * @name addClaimStep
 * @param {Object} value attributes of the step to be added
 * @param {string} value.title the title of the new step
 * @param {number} value.position the step array position where the new step should be inserted
 * @param {string} value.route the route location for the new step
 */
  addClaimStep(state, value) {
    const hasTitleAndRoute = value.title && value.route
    const hasStep = state.claimSteps.some(val => val.title === value.title)

    if (hasTitleAndRoute && !hasStep) {
      const newSteps = [...state.claimSteps]
      newSteps.splice(value.position, 0, {
        title: value.title,
        clickable: false,
        complete: false,
        route: value.route,
      })
      state.claimSteps = newSteps
    }
  },
  // TODO: use this when the API request returns vendor qualification = false AND the cleanup step is showing
  /**
 * @function
 * @name removeClaimStep
 * @param {Object} value attributes of the step to be removed
 * @param {string} value.title the title of the step to remove
 */
  removeClaimStep(state, value) {
    const newSteps = [...state.claimSteps]
    const position = newSteps.findIndex(step => step.title === value.title)
    if (position > -1) {
      newSteps.splice(position, 1)
      state.claimSteps = newSteps
    }
  },
}
