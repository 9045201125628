export const commonPropertyDamageOptions = {
  homeStructure: {
    label: 'Home Structure',
    description: 'Structure of your home (including roof, windows, walls, rooms, etc.)',
  },
  contentsOrPersonalProperty: {
    label: 'Contents or Personal Property',
    description: 'Your belongings (e.g. clothing and furniture)',
  },
  otherStructures: {
    label: 'Other Structures',
    description: 'Detached from your home (deck, shed, pool, fence or detached garage)',
  },
}

export const thirdPartyDamageOptions = {
  thirdPartyBuilding: {
    label: 'Residence/Building',
    description: 'Structure of another residence or building (including roof, windows, walls, rooms, etc.)',
  },
  thirdPartyPersonalProperty: {
    label: 'Personal Property',
    description: 'Someone else’s belongings (e.g. electronics, furniture, vehicle, etc.)',
  },
}

export function getWhatWasDamagedOptions(damageType) {
  let options
  if (damageType === 'thirdPartyPropertyDamage') {
    options = thirdPartyDamageOptions
  } else {
    options = commonPropertyDamageOptions
  }
  return Object.keys(options).map((key) => {
    return { label: options[key].label, value: key, desc: options[key].description }
  })
}

export function displayPropertyDamageLabel(propertyDamageValue) {
  const allDamageOptions = {
    ...commonPropertyDamageOptions,
    ...thirdPartyDamageOptions,
  }

  return allDamageOptions[propertyDamageValue].label
}

export const damageTypesWithoutPropertyDamage = ['dogBite', 'slipFall', 'otherInjury']
